<script setup lang="ts">
import { ref } from 'vue'
import Tooltip from './atomics/Tooltip.vue'

const numbers = ref([
  { nr: 0, name: 'Zero', active: true },
  { nr: 1, name: 'One', active: true },
  { nr: 2, name: 'Two', active: true },
  { nr: 3, name: 'Three', active: false },
  { nr: 4, name: 'Four', active: true },
  { nr: 5, name: 'Five', active: true },
  { nr: 6, name: 'Six', active: true },
  { nr: 103, name: 'One hundred three', active: true },
  { nr: 7, name: 'Seven', active: true },
  { nr: 8, name: 'Eight', active: false },
  { nr: 9, name: 'Nine', active: false },
  { nr: 10, name: 'Ten', active: true },
  { nr: 11, name: 'Eleven', active: true },
])
</script>
<template>
  <div class="col g-32">
    <div>
      <table>
        <thead>
          <tr>
            <th style="text-align: right">Nr</th>
            <th>Name</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="number in numbers" :key="number.nr">
            <td style="text-align: right">{{ number.nr }}</td>
            <td>{{ number.name }}</td>
            <td>{{ number.active }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="field col g-2">
      <label for="input-text">Input text</label>
      <input type="text" value="some text" id="input-text" />
    </div>
    <div class="field col g-8">
      <div class="row g-8">
        <input checked type="checkbox" id="input-checkbox" />
        <label for="input-checkbox">checkbox</label>
      </div>
      <div class="row g-8">
        <input type="checkbox" id="input-checkbox-2" />
        <label for="input-checkbox-2">checkbox</label>
      </div>
    </div>
    <div class="field col g-8">
      <div class="row g-8">
        <input type="radio" name="radio" value="radio1" checked id="input-radio-1" />
        <label for="input-radio-1">radio 1</label>
      </div>
      <div class="row g-8">
        <input type="radio" name="radio" value="radio2" id="input-radio-2" />
        <label for="input-radio-2">radio 2</label>
      </div>
      <div class="row g-8">
        <input type="radio" name="radio" value="radio3" id="input-radio-3" />
        <label for="input-radio-3">radio 3</label>
      </div>
    </div>
    <div class="field col g-2">
      <label for="select">Select</label>
      <select id="select">
        <option selected>Option 1</option>
        <option>Option 2</option>
      </select>
    </div>
    <div>
      <button class="button-primary">Button</button>
    </div>
    <div>
      <Tooltip
        text="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi labore, quia architecto dolores nisi, tempora animi eos eaque repellat doloremque laboriosam ab neque accusantium quasi omnis vero similique hic. Odio!"
      />
    </div>
    <p contenteditable="true">This is an editable paragraph</p>
    <div>
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
      <label>Label</label>
      <div>Div</div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Non reiciendis, quisquam ex vero, aperiam magni saepe aspernatur ipsa
        asperiores eius quasi earum animi ut eum, temporibus similique corporis maiores nihil!
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Non reiciendis, quisquam ex vero, aperiam magni saepe aspernatur ipsa
        asperiores eius quasi earum animi ut eum, temporibus similique corporis maiores nihil!
      </p>
    </div>
  </div>
</template>
