import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import './assets/base.css'

const app = createApp(App)

if (import.meta.env.PROD) {
  
  Sentry.init({
    app,
    dsn: 'https://935f8a7460c14ce38077f334d17080a6@o4503946418257920.ingest.sentry.io/4503946421207041',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['https://my-css-vargen2.vercel.app/', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    trackComponents: true,
    logErrors: true,
    
  
  })
}




app.use(router)
app.mount('#app')
