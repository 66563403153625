<script setup lang="ts">
import * as Sentry from '@sentry/vue'
import { RouterLink, RouterView, useRouter } from 'vue-router'
import { computed, onMounted, ref, watchEffect } from 'vue'

const router = useRouter()
const colorMode = ref<'light' | 'dark'>(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

watchEffect(() => {
  document.documentElement.setAttribute('data-theme', colorMode.value)
})

const showNav = ref(false)
const toggleNav = () => {
  showNav.value = !showNav.value
}

router.beforeEach(() => {
  showNav.value = false
})
onMounted(() => {
  setTimeout(async () => {
    //   if (router._nuxtLinkPreloaded.has(to)) { return }
    // router._nuxtLinkPreloaded.add(to)

    const paths = router
      .getRoutes()
      .filter((el) => el.name !== 'home' && el.name !== 'notfound')
      .map((el) => el.path)
    let aa = [] as any[]
    for (const esd of paths) {
      const qwe = router
        .resolve(esd)
        .matched.map((component) => component.components?.default)
        .filter((component) => typeof component === 'function')

      aa = [...aa, ...qwe]
    }
    // console.log(JSON.stringify(paths, null, 2))

    const components = aa

    const promises: Promise<any>[] = []
    for (const component of components) {
      // console.log(component)

      const promise = Promise.resolve((component as Function)()).catch(() => {
        console.log('erroe 1')
      })
      promises.push(promise)
    }
    await Promise.all(promises)
  }, 3000)
})
</script>

<template>
  <div class="col" style="height: 100%; padding: 100px 8px 0px 8px">
    <div
      class="no-print"
      style="position: fixed; background: var(--color-background);border-bottom: 1px solid var(--color-border); top: 0; left: 0; width: 100%; padding: 8px 8px 8px 8px"
    >
      <button @click="toggleNav" class="nav-button" :class="{ 'show-backdrop': showNav }">
        <img src="@/assets/menu_FILL0_wght400_GRAD0_opsz40.svg" class="icon" alt="menu" />
      </button>

      <nav class="nav" :class="{ 'show-nav': showNav }">
        <div class="nav-inner g-16">
          <RouterLink @click="showNav = false" class="router-link" to="/">Home</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/editor">Editor</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/cr-layout">CR Layout</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/table-editor">Table editor</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/table-editor-svg">Table editor SVG</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/low">Low</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/low-bg">Low bg</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/between">Between</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/reports">Reports</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/reportsv2">Reports v2</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/diag">Diag</RouterLink>
          <RouterLink @click="showNav = false" class="router-link" to="/route">Route</RouterLink>
        </div>
        <div class="row g-16" style="justify-content: end">
          <div class="row g-8">
            <input type="radio" name="color-mode" v-model="colorMode" value="light" id="light-mode" />
            <label for="light-mode">Light</label>
          </div>
          <div class="row g-8">
            <input type="radio" name="color-mode" v-model="colorMode" value="dark" id="dark-mode" />
            <label for="dark-mode">Dark</label>
          </div>
        </div>
      </nav>
    </div>

    <RouterView />
  </div>
</template>
<style scoped>
.nav {
  position: absolute;
  background: var(--color-background);
  display: none;
  width: 100%;
  top: 0;
  left: 0;
  padding: 8px;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1;
}
.nav-button {
  display: block;
  width: 36px;
  padding: 4px;
  background: transparent;
}

[data-theme='dark'] .icon {
  filter: invert(1);
}

.show-backdrop.nav-button::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #00000050;
}

.nav-inner {
  display: flex;
  flex-direction: column;
}

.show-nav.nav {
  display: flex;
}

@media screen and (min-width: 576px) {
  .nav {
    position: static;
    background: unset;
    display: flex;
    width: unset;
    top: unset;
    left: unset;
    padding: unset;
  }

  .nav-button {
    display: none;
  }
  .nav-inner {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
a.router-link {
  border-bottom: 4px solid transparent;
  text-decoration: none;
  font-weight: 700;
  color: var(--color-heading);
  padding: 0px 8px;
  transition: border 150ms;
}
a.router-link.router-link-exact-active {
  border-bottom-color: var(--primary-color);
}
@media print {
  #app > div {
    padding: 0px !important;
  }
}
</style>
