import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: '',
      component: Home,
      //redirect: '/table-editor',
    },
    {
      path: '/editor',
      name: 'editor',
      component: () => import('../views/editor.vue'),
    },
    {
      path: '/cr-layout',
      name: 'cr-layout',
      component: () => import('../views/cr-layout.vue'),
    },
    {
      path: '/low',
      name: 'low',
      component: () => import('../views/low.vue'),
    },
    {
      path: '/between',
      name: 'between',
      component: () => import('../views/between.vue'),
    },
    {
      path: '/low-bg',
      name: 'low-bg',
      component: () => import('../views/low-bg.vue'),
    },
    {
      path: '/table-editor',
      name: 'table-editor',
      component: () => import('../views/table-editor.vue'),
    },
    {
      path: '/table-editor-svg',
      name: 'table-editor-svg',
      component: () => import('../views/table-editor-svg.vue'),
    },
    {
      path: '/diag',
      name: 'diag',
      component: () => import('../views/diag.vue'),
    },
    {
      path: '/reports/:id?',
      name: 'reports',
      component: () => import('../views/reports.vue'),
    },
    {
      path: '/route/:id?',
      name: 'route',
      component: () => import('../views/route.vue'),
    },
    {
      path: '/reportsv2/:id?',
      name: 'reportsv2',
      component: () => import('../views/reportsv2.vue'),
    },
    { path: '/:pathMatch(.*)*', name: 'notfound', component: Home },
  ],
})

router.afterEach((to) => {
  const name = String(to.name)
  window.document.title = `my-css ${name}`
})

export default router
